import Vue from 'vue'
import {
  Button,
  Calendar,
  Cascader,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  DatetimePicker,
  Dialog,
  DropdownItem,
  DropdownMenu,
  Field,
  Form,
  Icon,
  Lazyload,
  Loading,
  Notify,
  Overlay,
  Picker,
  Popup,
  Radio,
  RadioGroup,
  Rate,
  Search,
  Step,
  Steps,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Tag,
  Toast,
  Uploader,
  Area,
  Image
} from 'vant'

Vue.use(Loading)
Vue.use(Overlay)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Cascader)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Field)
Vue.use(Tag)
Vue.use(Search)
Vue.use(Uploader)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Form)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Step)
Vue.use(Steps)
Vue.use(DatetimePicker)
Vue.use(Toast)
Vue.use(Rate)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Notify)
Vue.use(Calendar)
Vue.use(Area)
Vue.use(Image)

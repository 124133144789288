import Vue from 'vue'
import VueRouter from 'vue-router'
import dormitory from '@/router/dormitory'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: e => require(['@/views/login'], e)
  },
  {
    path: '/login',
    component: e => require(['@/views/login'], e)
  },
  // cas统一身份认证
  {
    path: '/casLogin',
    component: e => require(['@/views/casLogin'], e)
  },
  ...dormitory
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

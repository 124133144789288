export default [
  // 选择寝室楼
  {
    path: '/dormitory/areaChoose',
    component: e => require(['@/views/dormitory/areaChoose'], e)
  },
  // 选择寝室房间
  {
    path: '/dormitory/roomChoose',
    component: e => require(['@/views/dormitory/roomChoose'], e)
  },
  // 入住单
  {
    path: '/dormitory/checkInfo',
    name: 'checkInfo',
    component: e => require(['@/views/dormitory/checkInfo'], e)
  },
  // 教师 寝室总统计
  {
    path: '/dormitory/totalStatus',
    component: e => require(['@/views/dormitory/totalStatus'], e)
  },
  // 教师 公寓楼层详情
  {
    path: '/dormitory/areaStatus/:area',
    name: 'areaStatus',
    component: e => require(['@/views/dormitory/areaStatus'], e),
    props: true
  },
  // 教师 寝室详情
  {
    path: '/dormitory/roomStatus/:bedroomId/:roomName',
    name: 'roomStatus',
    component: e => require(['@/views/dormitory/roomStatus'], e),
    props: true
  }
]
